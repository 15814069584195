import { Grid, IconButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TimeInput } from '../Common/'
import moment from 'moment'
import ClinicDaysInput from './IntervalWeekdays'
import { Delete } from '@material-ui/icons'

const formatTime = (time) => time.format('HH:mm')

const Interval = ({
  startTime,
  endTime,
  weekDays,
  onIntervalInput,
  removeInterval,
  index,
}) => {
  const [startInterval, setStartInterval] = useState(moment(startTime, 'HH:mm'))
  const [endInterval, setEndInterval] = useState(moment(endTime, 'HH:mm'))

  const handleClinicDayInput = (newIntervalsDay) => {
    if (newIntervalsDay === '') {
      return onIntervalInput(null)
    }
    onIntervalInput(
      `${formatTime(startInterval)} ${formatTime(
        endInterval
      )} ${newIntervalsDay}`
    )
  }

  const handleTimeInput = ({ name, value }) => {
    if (name === 'start') {
      setStartInterval(value)
      onIntervalInput(
        `${formatTime(value)} ${formatTime(endInterval)} ${weekDays}`
      )
    }
    if (name === 'end') {
      setEndInterval(value)
      onIntervalInput(
        `${formatTime(startInterval)} ${formatTime(value)} ${weekDays}`
      )
    }
  }

  useEffect(() => {
    setStartInterval(moment(startTime, 'HH:mm'))
    setEndInterval(moment(endTime, 'HH:mm'))
  }, [startTime, endTime])

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent='center'
      alignItems='center'
    >
      <Grid item xs sm md={3}>
        <TimeInput
          label='From'
          value={startInterval}
          onChange={(value) => handleTimeInput({ value, name: 'start' })}
        ></TimeInput>
      </Grid>
      <Grid item xs sm md={3}>
        <TimeInput
          label='To'
          value={endInterval}
          onChange={(value) => handleTimeInput({ value, name: 'end' })}
        ></TimeInput>
      </Grid>
      <Grid item xs={12} sm>
        <ClinicDaysInput
          weekDays={weekDays}
          onIntervalDay={handleClinicDayInput}
        />
      </Grid>
      <Grid item>
        <IconButton
          color='secondary'
          aria-label='delete'
          size='small'
          onClick={() => removeInterval(index)}
        >
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default Interval
