import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    height: "100%",
    overflow: "hidden",
    "& .fc-day, .fc-event": {
      fontFamily: theme.typography.fontFamily,
    },
    "& .fc-list-event.cancel": {
      backgroundColor: "#dedede",
      color: "#606060 !important",
    },
  },
  calendarRoot: {
    padding: theme.spacing(0, 2),
  },
  progressBar: {
    position: "absolute",
    top: 0,
    height: 4,
    width: "100%",
  },
}))
