import React, { useEffect, createContext, useState, Component } from "react";
import Calendar from "../Calendar";
import { getCookie } from "utils/cookies";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Settings from "components/Settings";
import { getFilterOptions, getRole } from "../../apis";
import {Report} from 'components/Report/index'
export const AuthorizationContext = createContext();

function App() {
  const [authToken, setAuthToken] = useState();
  const [role, setRole] = useState("");
  const [filterOptions, setFilterOptions] = useState({});

  const getAuthToken = async () => {
    const authToken = await getCookie("Authorization");
    setAuthToken(authToken);
  };

  const saveRole = async () => {
    if (!authToken) return;
    const token = authToken.split(" ")[1];
    const role = await getRole(token);
    setRole(role);
  };
  
  const fetchFilterOptions = async (token) => {
    if (!authToken) return;
    const res = await getFilterOptions(token);
    setFilterOptions(res.message);  
  };
  useEffect(() => {
    getAuthToken();
    // fetchFilterOptions();
  }, [filterOptions]);

  useEffect(() => {
    if (authToken) {                            
      const token = authToken.split(" ")[1];   
      console.log(token, "Extracted token");  
      saveRole();                                
      fetchFilterOptions(token);                 
    }
  }, [authToken]);  
  return (
    <AuthorizationContext.Provider value={{ authToken, role, filterOptions }}>
      <Router>
        <Routes>
          <Route path="/*" element={<Calendar />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/report" element={<Report />} />
        </Routes>
      </Router>
    </AuthorizationContext.Provider>
  );
}

export default App;
