import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
    paper: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      zIndex: '20',
      overflow: "hidden",
      transform: 'translate(-50%,-50%)',
      width: '80%',
      height: '85%',
      borderRadius: '7px',
      outline: '0',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      fontFamily: 'AvenirNext, Avenir, Roboto, sans-serif'
    },
    header: {
      width: '100%',
      backgroundColor: '#C4C4C4',
      textAlign: 'center',
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      padding: '1rem 1rem 1rem 4rem'
      // justifyContent: 'center'
    },
    title: {
      margin: 0,
      fontWeight: 200,
      marginTop: '.4rem',
      display: 'inline-block',
      color: 'white'
    },
    containerData: {
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
      margin: '5px 0'
    },
    closeButton: {
      width: '30px',
      height: '30px',
      padding: '0',
      left: '0',
      borderRadius: '50%',
      textDecoration: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      color: 'white',
      FontWeight: 'bold'
    },
    closeIcon: {
      height: '100%',
      fontSize: 'xx-large'
    },
    buttonContainer: {
      justifyContent: 'center',
      // marginBottom: '0.25rem',
      margin: "0 0.15rem 0.25rem 0.15rem"
    },
    buttonStyle: {
      backgroundColor: '#00BEB5',
      width: 'auto',
      color: 'white',
      wordWrap: 'break-word',
      justifyContent: 'center',
      display: 'block',
      textAlign: 'center',
      //fontSize: "12px",
      lineHeight: '12px',
      padding: '9px',
      margin: '0.2rem'
    },
    boxClass: {
      '&::-webkit-scrollbar': {
        width: 7
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: `1px solid slategrey`
      }
    },
    listItemStyle: {
      padding: 0,
      margin: 0,
    }
  }))