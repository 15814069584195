import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IFrame from "./IFrame";

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRoot: {
    margin: 0,
    padding: theme.spacing(0.5),
    background: theme.palette.grey[100],
    display: "flex",
  },
  closeButton: {
    marginLeft: "auto",
  },
  dialogContent: ({ id }) => ({
    position: "relative",
    padding: 0,
    overflowY: "hidden",
    height: 500,
    maxHeight: "100%",
    [`& #${id}`]: {
      maxHeight: "100%",
      height: "100%",
    },
  }),
}));

function AppointmentForm({ closeModal, appointment, ...others }) {
  console.log("Testing--");
  console.log("Testing111-----");
  console.log("Testing2-----)");
  console.log("Testing3-----)");
  console.log("Testing4-----");
  const classes = useStyles({ id: others.iFrameId });
  const DialogTitle = (props) => {
    const { onClose, ...other } = props;
    return (
      <MuiDialogTitle
        disableTypography
        className={classes.titleRoot}
        {...other}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md" className={classes.root}>
      <DialogTitle onClose={closeModal} />
      <DialogContent className={classes.dialogContent}>
        <IFrame {...others} appointment={appointment} />
      </DialogContent>
    </Dialog>
  );
}

AppointmentForm.propTypes = {};

export default AppointmentForm;
