import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { Settings } from "@material-ui/icons"
import moment from "moment"
import { DateInput, NumberInput, SelectInput } from "./Inputs/Common"
import Holidays from "./Inputs/Holiday"
import Intervals from "./Inputs/Interval"
import BlockOff  from "./Inputs/Blockoff"
import appointmentLengthOptions from "./appointmentLengthOptions.json"

const SettingsForm = ({ settingsValues, setSettingsValues ,selectedClinicData}) => {
  const {
    startEndDate,
    advanceApp,
    maxAppDay,
    schNotice,
    maxAppSlot,
    slotDuration,
    intervals,
    blockoutDates,
    r_qID
  } = settingsValues
console.log(slotDuration,"slotDuration")
console.log(startEndDate,"startEndDate")
console.log(selectedClinicData,"selectedClinicData")
console.log(selectedClinicData,"selectedClinicDataa")
console.log(r_qID,"r_qID")
  const handleDateInput = ({ name, value }) => {
    const startEndDateSplit = startEndDate.split(" ")

    if (name === "startDate") {
      startEndDateSplit[0] = moment(value).format("YYYY-MM-DD")
    }

    if (name === "endDate") {
      startEndDateSplit[1] = moment(value).format("YYYY-MM-DD")
    }

    setSettingsValues((prevSettings) => {
      return {
        ...prevSettings,
        startEndDate: startEndDateSplit.join(" "),
      }
    })
  }

  const handleNumberInput = ({ name, value }) => {
    if (isNaN(value)) {
      return
    }

    setSettingsValues((prevSettings) => {
      return {
        ...prevSettings,
        [name]: value,
      }
    })
  }

  const cleanEmptyValue = ({ name, value }) => {
    if (value === "") {
      setSettingsValues((prevSettings) => {
        return {
          ...prevSettings,
          [name]: 0,
        }
      })
    }
  }

  const handleLengthApptInput = (value) => {
    setSettingsValues((prevSettings) => {
      return {
        ...prevSettings,
        slotDuration: value.split(" ")[0],
      }
    })
  }

  const handleHolidayInput = ({ value, action }) => {
    if (action === "add") {
      setSettingsValues((prevSettings) => {
        return {
          ...prevSettings,
          blockoutDates: [
            ...new Set([...prevSettings.blockoutDates, `${value} ${value}`]),
          ],
        }
      })
    }

    if (action === "remove") {
      setSettingsValues((prevSettings) => {
        return {
          ...prevSettings,
          blockoutDates: [
            ...new Set(
              prevSettings.blockoutDates.filter((holiday) => holiday !== value)
            ),
          ],
        }
      })
    }

    return
  }

  const handleIntervalsInput = ({ value, index }) => {
    setSettingsValues((prevSettings) => {
      return {
        ...prevSettings,
        intervals: prevSettings.intervals
          .map((interval, i) => (i === index ? value : interval))
          .filter((interval) => interval !== null),
      }
    })
  }

  const addNewInterval = () => {
    setSettingsValues((prevSettings) => {
      return {
        ...prevSettings,
        intervals: [
          ...prevSettings.intervals,
          "12:00 12:00 Mon,Tue,Wed,Thu,Fri",
        ],
      }
    })
  }

  const removeInterval = (index) => {
    setSettingsValues((prevSettings) => {
      return {
        ...prevSettings,
        intervals: prevSettings.intervals.filter((_, i) => i !== index),
      }
    })
  }

  return (
    <Grid
      direction="column"
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <Settings />
          </Grid>
          <Grid item>
            <Typography variant="h1" component="h1">
              Clinic Schedule Settings
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" spacing={2} xs md={8}>
        <Grid item xs={12} sm md={6}>
          <DateInput
            label="Start date"
            value={startEndDate.split(" ")[0]}
            onChange={(value) => handleDateInput({ name: "startDate", value })}
          />
        </Grid>
        <Grid item xs={12} sm md={6}>
          <DateInput
            label="End date"
            value={startEndDate.split(" ")[1]}
            onChange={(value) => handleDateInput({ name: "endDate", value })}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" spacing={2} xs md={8}>
        <Grid item xs={12} sm md={6}>
          <NumberInput
            onBlur={(e) =>
              cleanEmptyValue({ name: "advanceApp", value: e.target.value })
            }
            label="Days in advance an appointment can be scheduled"
            value={advanceApp}
            onChange={(e) =>
              handleNumberInput({ name: "advanceApp", value: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm md={6}>
          <NumberInput
            onBlur={(e) =>
              cleanEmptyValue({ name: "maxAppDay", value: e.target.value })
            }
            label="Max appointments per day"
            value={maxAppDay}
            onChange={(e) =>
              handleNumberInput({ name: "maxAppDay", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" spacing={2} xs md={8}>
        <Grid item xs={12} sm md={6}>
          <NumberInput
            onBlur={(e) =>
              cleanEmptyValue({ name: "schNotice", value: e.target.value })
            }
            label="Min scheduling notice (hours)"
            value={schNotice}
            onChange={(e) =>
              handleNumberInput({ name: "schNotice", value: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm md={6}>
          <NumberInput
            onBlur={(e) =>
              cleanEmptyValue({ name: "maxAppSlot", value: e.target.value })
            }
            label="Max appointments per time slot"
            value={maxAppSlot}
            onChange={(e) =>
              handleNumberInput({ name: "maxAppSlot", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" spacing={2} xs md={8}>
        <Grid item xs={12} sm md={12}>
          <SelectInput
            id="appointmentLength"
            name="appointmentLength"
            label="Length of appointment"
            options={appointmentLengthOptions}
            value={slotDuration}
            setValue={handleLengthApptInput}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        xs
        md={8}
      >
        <Intervals
          intervals={intervals}
          onIntervalInput={handleIntervalsInput}
          addNewInterval={addNewInterval}
          removeInterval={removeInterval}
        />
      </Grid>
      {
        //* This is not being used yet
        /* <Grid item container justifyContent='center' spacing={2}>
        <Grid item xs={12} sm md={8}>
          <TimeInput
            label='Lunch Time'
            value={lunchTime}
            onChange={setLunchTime}
          />
        </Grid>
      </Grid> */
      }
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        xs
        md={8}
      >
        <Holidays
          holidays={blockoutDates}
          onHolidayInput={handleHolidayInput}
        />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        xs
        md={8}
      >
        <BlockOff
        selectedClinicData={selectedClinicData}
          intervals={intervals}
          onIntervalInput={handleIntervalsInput}
          addNewInterval={addNewInterval}
          removeInterval={removeInterval}
          slotDuration={slotDuration}
          startEndDate={startEndDate}
          maxAppSlot={maxAppSlot}
          advanceApp={advanceApp}
          r_qID={r_qID}/>
      </Grid>
    </Grid>
  )
}

export default SettingsForm
