import React from "react"

import Button from "@material-ui/core/Button"

import TooltipBubble from "../../TooltipBubble"

const OpenTelehealtButton = ({ disabledButton, classStyle, url }) => {
  return disabledButton ? (
    <TooltipBubble>
      <Button
        variant="contained"
        color="primary"
        className={classStyle}
        onClick={() => window.open(url, "_blank")}
        disabled={disabledButton}
      >
        Open telehealth
      </Button>
    </TooltipBubble>
  ) : (
    <Button
      variant="contained"
      color="primary"
      className={classStyle}
      onClick={() => window.open(url, "_blank")}
      disabled={disabledButton}
    >
      Open telehealth
    </Button>
  )
}

export default OpenTelehealtButton
