import React from "react"

import Button from "@material-ui/core/Button"

import TooltipBubble from "../../TooltipBubble"

const ResendButton = ({
  disabledButton,
  classStyle,
  setNotificationMessage,
  setNotificationOpen,
  setNotificationStatus,
  sendReminder,
  appointmentID,
}) => {
  return disabledButton ? (
    <TooltipBubble titleText="The current option is disabled because the actual appointment is old">
      <Button
        variant="contained"
        color="primary"
        className={classStyle}
        disabled
      >
        Resend telehealth link
      </Button>
    </TooltipBubble>
  ) : (
    <Button
      variant="contained"
      color="primary"
      className={classStyle}
      onClick={async () => {
        setNotificationMessage("Sending Reminder...")
        setNotificationStatus("info")
        setNotificationOpen(true)
        const value = await sendReminder(appointmentID)
        setNotificationOpen(false)
        if (value) {
          setNotificationMessage("Reminder sent!")
          setNotificationStatus("success")
        } else {
          setNotificationMessage("ERROR WITH SERVER")
          setNotificationStatus("error")
        }
        setNotificationOpen(true)
      }}
    >
      Resend telehealth link
    </Button>
  )
}

export default ResendButton
