import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  overrides: {
    MuiSelect: {
      outlined: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiGrid: {
      root: {
        overflow: 'visible',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        whiteSpace: 'nowrap',
      },
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
      outlinedSecondary: {
        color: '#909090',
        border: '1px solid #909090',
      },
    },
    MuiDialog: {
      root: {
        zIndex: '10000001!important',
      },
    },
  },
  palette: {
    background: {
      light: '#FBF9F9',
      dark: '#EDEDED',
    },
    primary: {
      light: '#FFFFFF',
      main: '#00BEB5',
    },
    secondary: {
      main: '#ef5350',
    },
    border: {
      light: '#E2E2E2',
    },
    text: {
      secondary: '#909090',
    },
  },
  typography: {
    fontFamily: ['Avenir', 'AvenirNext', 'Roboto', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
      fontFamily: 'AvenirNext, Avenir, Roboto, sans-serif',
      whiteSpace: 'nowrap',
    },
    h1: {
      fontFamily: 'AvenirNext, Avenir, Roboto, sans-serif',
      fontSize: 22,
      color: '#3F3F3F',
    },
    body1: {
      color: '#3F3F3F',
    },
  },
})
