import React from 'react'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/styles'
import {
  TextField,
  Grid,
  InputAdornment,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    height: '100%',
    padding: 0,
    
  },
  adormentStyle: {
    padding: 0,
  },
  buttonStyle: {
    // right: "13px",
    padding: "8px",
    boxShadow: "none",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
  }
  
}))


const ClipboardButton = ({ value, validation }) => {
  const classes = useStyles()
  return (
    // <Grid sx={12}>
      <TextField
        // fullWidth
        value={`PIN: ${value}`}
        variant='outlined'
        color="primary"
        focused
        size="small"
        className={classes.input}
        InputProps={{
          className: classes.input,
          startAdornment: (
            <InputAdornment position="start">
              {!validation ?
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonStyle}
                onClick={(e) => {
                  e.preventDefault()
                  navigator.clipboard.writeText(value)}}
              >
                Copy to clipboard
              </Button>
              : 
              <Button
                variant='contained'
                color='primary'
                disabled
                className={classes.buttonStyle}
                onClick={(e) => {
                  e.preventDefault()
                  navigator.clipboard.writeText(value)}}
              >
                Copy to clipboard
              </Button>
              }
            </InputAdornment>
          
          ),
          readOnly: true,
        }}
      />
    // </Grid>
  )
}

export default ClipboardButton
