import { DatePicker } from '@material-ui/pickers'
import React from 'react'

const DateInput = ({
  label,
  value,
  onChange,
  name,
  id,
  variant = 'inline',
  autoOk = true,
}) => {
  return (
    <DatePicker
      autoOk={autoOk}
      variant={variant}
      label={label}
      inputVariant='outlined'
      size='small'
      value={value}
      onChange={onChange}
      name={name}
      id={id}
      format={'MM/DD/YYYY'}
      fullWidth
    />
  )
}

export default DateInput
