import React from "react"

import Button from "@material-ui/core/Button"
import { Edit } from "@material-ui/icons"

const EditButton = ({
  classStyle,
  onClickEdit,
  SubmissionID,
  appointment,
  text = "Edit Appointment",
  WasMadeByAProvider,
  ...props
}) => {
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      className={classStyle}
      onClick={() => {
        console.log(SubmissionID)
        onClickEdit({ id: SubmissionID, wasMadeByProvider: WasMadeByAProvider,appointment:appointment })
      }}
      endIcon={<Edit />}
      {...props}
    >
      {text}
    </Button>
  )
}

export default EditButton
