import { DateTimePicker } from '@material-ui/pickers'
import React from 'react'

const DateTimeInput = ({ label, value, onChange, name, id }) => {
  return (
    <DateTimePicker
      autoOk
      variant='inline'
      label={label}
      inputVariant='outlined'
      size='small'
      value={value}
      onChange={onChange}
      fullWidth
      name={name}
      id={id}
      format={'MM/DD/YYYY HH:mm'}
    />
  )
}

export default DateTimeInput
