/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from "react";
import BlockOff from "./SettingsForm/Inputs/Blockoff";
import { getCookie } from "utils/cookies";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Cached, Cancel, Error, Save, CalendarToday } from "@material-ui/icons";
import {
  saveClinicSettings,
  getClinicSettings,
  getSettingsFilterOptions,
} from "../../apis";
import { AuthorizationContext } from "components/App";
import ErrorNotification from "components/common/ErrorNotification";
import { useNavigate } from "react-router-dom";
import initialSettings from "./initialSettings.json";
import SettingsForm from "./SettingsForm";
import { SelectInput } from "./SettingsForm/Inputs/Common";

const sleep = (time) => new Promise((res) => setTimeout(res, time));

const Settings = () => {
  const { filterOptions, role } = useContext(AuthorizationContext);
  const navigate = useNavigate();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [clinicOptions, setClinicOptions] = useState([]);
  const [clinic, setClinic] = useState("");
  const [showSettings, setShowSettings] = useState("");
  const [saving, setSaving] = useState(false);
  const [schedulingType, setSchedulingType] = useState("provider");
  const [settingsValues, setSettingsValues] = useState(initialSettings);
  const [errorMessage, setErrorMessage] = useState("");
  const [reqId, setReqId] = useState("");
  const settingsRef = useRef(initialSettings);
  console.log(settingsValues, "settingsValues");
  const settingsChanged = Object.keys(settingsValues).filter(
    (key) => settingsValues[key] !== settingsRef.current[key]
  );
  const filtersBeingLoaded = Object.keys(filterOptions).length === 0;
  const handleSubmitClinicSettings = async (e) => {
    e.preventDefault();
    setSaving(true);
    const query = settingsChanged.map((key) => {
      return {
        action: key,
        value: settingsValues[key],
        r_qID: settingsValues["r_qID"],
        clinic,
      };
    });

    try {
      const authToken = await getCookie("Authorization");
      await saveClinicSettings(query, authToken); // Assuming authToken is passed as a second argument
      settingsRef.current = settingsValues;
      setSaving(false);
      setNotificationMessage("Settings Saved Successfully...");
      setNotificationStatus("success");
      setNotificationOpen(true);
      await sleep(2500);
      setNotificationOpen(false);
    } catch (error) {
      setSaving(false);
      setNotificationMessage("SERVER ERROR...");
      setNotificationStatus("error");
      setNotificationOpen(true);
      await sleep(2500);
      setNotificationOpen(false);
      console.error(error);
    }
  };

  const loadClinicSettings = async () => {
    if (!clinic) return;
    setShowSettings("loading");
    setSettingsValues(initialSettings);
    settingsRef.current = initialSettings;
    try {
      const clinicName =
        schedulingType === "provider" ? clinic : `${clinic} Self`;
      const authToken = await getCookie("Authorization"); // Get the auth token

      const clinicSettings = await getClinicSettings(clinicName, authToken);
      settingsRef.current = clinicSettings;
      setSettingsValues(clinicSettings);
      setShowSettings("ready");
    } catch (err) {
      setShowSettings("error");

      if (!err?.response?.data.status) return console.error(err);

      setErrorMessage(err.response.data.status);
    }
  };

  const handleResetButton = async () => {
    await loadClinicSettings();
    setNotificationMessage(
      "Clinic Settings has been reset to previous state..."
    );
    setNotificationStatus("info");
    setNotificationOpen(true);

    await sleep(2500);
    setNotificationOpen(false);
  };

  // const getClinicOptions = (a) => {
  //   if (filtersBeingLoaded) return;
  //   getSettingsFilterOptions()
  //     .then((res) => {
  //       const clinicOptionsArr = res?.message.clinicName.map((clinic) => {
  //         return { title: clinic };
  //       });

  //       setClinicOptions(clinicOptionsArr);
  //     })
  //     .catch((err) => console.log(err));

  // };
  const getClinicOptions = async () => {
    if (filtersBeingLoaded) return;
    try {
      const authToken = await getCookie("Authorization"); // Get the auth token

      // Pass the authToken to the getSettingsFilterOptions function
      const res = await getSettingsFilterOptions(authToken);
      const clinicOptionsArr = res?.message.clinicName.map((clinic) => {
        return { title: clinic };
      });
      setClinicOptions(clinicOptionsArr);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadClinicSettings();
    console.log(clinic, "selectedClinicData");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic, schedulingType]);

  useEffect(() => {
    getClinicOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);
  if (!role || filtersBeingLoaded) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <CircularProgress size={100} />
        </Grid>
      </Grid>
    );
  }
  if (role !== "admin") {
    navigate("/");
  }
  let clinicJotSettings;
  if (showSettings === "loading") {
    clinicJotSettings = (
      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "8rem" }}
        >
          <CircularProgress size={80} />
        </Grid>
      </Grid>
    );
  } else if (showSettings === "ready") {
    clinicJotSettings = (
      <SettingsForm
        selectedClinicData={clinic}
        settingsValues={settingsValues}
        setSettingsValues={setSettingsValues}
      />
    );
  } else if (showSettings === "error") {
    clinicJotSettings = (
      <Grid item>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Error color="secondary" fontSize="large" />
          <Typography variant="h6">
            {errorMessage
              ? errorMessage
              : "There has been an error, please try again later..."}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    clinicJotSettings = undefined;
  }
  return (
    <Container fluid maxWidth>
      <Grid
        container
        spacing={1}
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
      >
        <Grid
          container
          spacing={1}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item xs md={12} lg={1}>
            <Box paddingY={4}>
              <Button
                style={{ width: "158px" }}
                startIcon={<CalendarToday />}
                variant="contained"
                type="button"
                color="primary"
                onClick={() => navigate("/")}
                fullWidth
              >
                Back To Calendar
              </Button>
            </Box>
          </Grid>
          <Grid item xs md={12} lg={11}>
            <form onSubmit={handleSubmitClinicSettings}>
              <Box paddingY={4}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    spacing={2}
                    xs
                    md={8}
                  >
                    <Grid item xs={12}>
                      <SelectInput
                        options={clinicOptions}
                        name="clinic"
                        id="clinic"
                        label="Select Clinic"
                        setValue={setClinic}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                      >
                        <Grid item>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="Scheduling Type"
                              name="Scheduling Type"
                              value={schedulingType}
                              onChange={(e) => {
                                setSchedulingType(e.target.value);
                              }}
                              row
                            >
                              <FormControlLabel
                                value="provider"
                                control={<Radio color="primary" />}
                                label="Provider"
                              />
                              <FormControlLabel
                                value="self"
                                control={<Radio color="primary" />}
                                label="Self"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md lg={4}>
                      <Button
                        disabled={settingsChanged.length <= 0 || saving}
                        startIcon={<Save />}
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md lg={4}>
                      <Button
                        disabled={settingsChanged.length <= 0 || saving}
                        startIcon={<Cached />}
                        variant="contained"
                        type="button"
                        onClick={handleResetButton}
                        fullWidth
                      >
                        Reset
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md lg={2}>
                    <Button
                      startIcon={<Cancel />}
                      variant="contained"
                      type="button"
                      color="secondary"
                      onClick={() => navigate("/")}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </Grid> */}
                  </Grid>
                  {clinicJotSettings}
                </Grid>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ErrorNotification
          open={notificationOpen}
          title={notificationMessage}
          onClose={() => setNotificationOpen(false)}
          severity={notificationStatus}
        />
      </Grid>
    </Container>
  );
};

export default Settings;
